body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  color: #fff;
}
a:hover {
  color: #cc0000;
}
button {
  cursor: pointer;
}
.App {
  text-align: center;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.App::-webkit-scrollbar {
  display: none;
}

.img-btn {
  width: 100%;
}

.flag-btn {
  width: 100%;
  margin-top: -10px;
}

.backward-btn {
  width: 90%;
  height: 90%;
  margin-top: -3px;
  margin-left: -3px;
}

.forward-btn {
  width: 90%;
  height: 90%;
  margin-top: -3px;
  margin-right: -3px;
}

.timeline-btn {
  width: 70%;
  height: 70%;
  margin-top: 0px;
  transform: rotate(90deg);
}

.timeline-btn-active {
  background-color: #cc0000 !important;
  color: #fff !important;
}

.button {
  background-color: #fff;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 20px;
}

.button:hover {
  background-color: #cc0000;
  color: #fff;
}

#welcome {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  position: absolute;
}

#welcome video[poster] {
  object-fit: cover;
}

#welcome .text {
  text-align: center;
  font-size: 7dp;
  cursor: pointer;
  color: #fff;
}

#interface-logo {
  position: absolute;
  top: 0;
  left: 5vw;
  width: 80px;
  height: 300px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-timeline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  height: 80px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-play {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100vw;
  text-align: center;
  height: 100px;
  z-index: 4;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-skip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  height: 50px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-mute {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.3%;
  text-align: center;
  height: 50px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-pause {
  position: absolute;
  top: 50px;
  right: 0;
  padding-right: 0.3%;
  text-align: center;
  height: 50px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-backward {
  position: absolute;
  top: 100px;
  right: 0;
  padding-right: 0.3%;
  text-align: center;
  height: 50px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-forward {
  position: absolute;
  top: 150px;
  right: 0;
  padding-right: 0.3%;
  text-align: center;
  height: 50px;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#interface-menu {
  position: absolute;
  bottom: 0vh;
  left: 5vw;
  width: 60px;
  height: 60px;
  /*background: red;*/
  cursor: pointer;
  z-index: 3;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slide-in-bottom1 {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s both;
}
.slide-in-bottom2 {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.2s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}
.slide-in-bottom3 {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.4s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
}

#interface-menu-button1 {
  position: absolute;
  bottom: 230px;
  left: 5.3vw;
  width: 50px;
  height: 50px;
  z-index: 5;
  /*background: black;*/
}

#interface-menu-button2 {
  position: absolute;
  bottom: 160px;
  left: 5.3vw;
  width: 50px;
  height: 50px;
  z-index: 5;
}

#interface-menu-button3 {
  position: absolute;
  bottom: 90px;
  left: 5.3vw;
  width: 50px;
  height: 50px;
  z-index: 5;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100vw;
  height: 100%;
  z-index: 4;
}

#overlay .content {
  position: relative;
  justify-content: center;
  margin: 15vh auto;
  height: auto;
  color: #fff;
  text-align: center;
  font-size: 20px;
  /*background-color: rgba(78, 77, 77, 0.8);*/
  background-image: url("./images/FENETRE-INTERFACE-TEXTE-INTRO.png");
  background-position: center;
  background-repeat: no-repeat;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  border-radius: 50px;
  padding: 20px 80px;
}

#overlay .content::-webkit-scrollbar {
  display: none;
}

#overlay .content .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#overlay .content button:hover {
  background-color: #cc0000;
  cursor: pointer;
}

#v {
  display: none;
  z-index: 1;
  width: 100% !important;
  height: 100vh !important;
}

#v2 {
  display: none;
  z-index: 1;
  width: 100% !important;
  height: 100vh !important;
}

#orientation {
  color: #fff;
}

/***** 1. Rotor ********/
@-webkit-keyframes rotor {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotor {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotor {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

@keyframes rotor {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button-wrap-1 {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

.button-wrap-1 .clicker {
  left: 0;
  width: 40px;
  height: 30px;
  margin: 4px;
  background-color: #fff;
  color: #cc0000;
  border-radius: 50px;
  z-index: 2;
  position: absolute;
  padding: 1px 1px;
  padding-top: 11px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.clicker-right {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.button-wrap-1 .clicker:active {
  background-color: #cc0000;
  color: white;
}

.button-wrap-1 .circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  /*background-image: linear-gradient(45deg, transparent 0%, transparent 30%, red 30%, red 70%, transparent 70%, transparent 100%);*/
  z-index: 1;
  position: absolute;
}

.button-wrap-1 .clicker:hover + .circle,
.button-wrap-1 .clicker:active + .circle {
  background-image: linear-gradient(
    45deg,
    transparent 0%,
    transparent 30%,
    #cc0000 30%,
    #cc0000 70%,
    transparent 70%,
    transparent 100%
  );
  -webkit-animation: rotor 1.5s linear 0s infinite normal;
  -mox-animation: rotor 1.5s linear 0s infinite normal;
  -o-animation: rotor 1.5s linear 0s infinite normal;
  animation: rotor 1.5s linear 0s infinite normal;
  animation-direction: reverse;
}

svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 180px;
}

.close {
  position: absolute;
  right: 0;
}

.close svg {
  left: 0;
  top: 10px;
  position: relative;
}

.close svg:hover {
  color: #ffffff;
  cursor: pointer;
}

.containerGrid {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 15px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
}

.containerGrid * {
  height: 50px;
  text-align: center;
}

.containerGrid button {
  background-color: rgba(255, 255, 255, 0.6);
  color: #cc0000;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
}

.containerGrid button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #cc0000;
}

table {
  width: 100%;
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;
}
table th {
  border: 1px solid #b3adad;
  padding: 5px;
  background: #ffffff;
  color: #cc0000;
}
table td {
  border: 1px solid #b3adad;
  text-align: left;
  padding: 5px;
  color: #fff;
}

.ScreenOrientation svg {
  left: 0;
  top: 0;
  z-index: 99999999;
  background-color: #000;
  height: 100vh;
}

circle {
  fill: transparent;
  stroke: orange;
  stroke-width: 10px;
  stroke-dasharray: 471;
  animation: clock-animation 10s linear infinite;
}

@keyframes clock-animation {
  0% {
    stroke-dashoffset: 471;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.skip:hover {
  color: #cc0000 !important;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext2,
.tooltip:hover .tooltiptextIcon,
.tooltip:hover .tooltiptextTimeline {
  visibility: visible;
}

.tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /*left: 50%;*/
  margin-left: -72px;
}

.tooltiptext2 {
  visibility: hidden;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /*left: 50%;*/
  margin-left: -82px;
}

.tooltiptextIcon {
  visibility: hidden;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /*left: 50%;*/
  margin-left: -62px;
}

.tooltiptextTimeline {
  visibility: hidden;
  width: auto;
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /*left: 50%;*/
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-21 15:57:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.switchBox {
  display: flex;
  align-items: center;
}

.switchBox div {
  padding-left: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #cc0000;
}

input:disabled + .slider {
  background-color: rgba(201, 0, 0, 0.6);
}

input:focus + .slider {
  box-shadow: 0 0 1px #cc0000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
